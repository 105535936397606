import axios from 'axios'

export default {
  namespaced: true,
  state: {
    logged: false,
    user: null,
  },
  mutations: {
    SET_LOGGED(state, logged) {
      state.logged = logged
    },
    SET_USER(state, user) {
      state.user = user
    },
  },
  actions: {
    async login({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/login', credentials)
      await dispatch('me')
    },
    async logout({ dispatch, commit }) {
      await axios.post('/logout')
      commit('SET_USER', null)
      await dispatch('me')
    },
    async me({ commit }) {
      try {
        const { data } = await axios.get('/api/v1/protegida')
        console.log(data)
        commit('SET_LOGGED', true)
        commit('SET_USER', data)
      } catch (e) {
        commit('SET_LOGGED', false)
        commit('SET_USER', null)
      }
    },
  },
}
